import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { takeUntil, filter, first } from 'rxjs/operators';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { sha256 } from 'js-sha256';

import { environment } from '../environments/environment';

import { SidenavService } from './shared/services/sidenav.service';
import { UserService } from './user.service';
import { AppTranslateService } from './shared/services/app-translate.service';
import { ChannelTalkService } from './channel-talk.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private unsubscriber: Subject<void> = new Subject<void>();

  @ViewChild(MatSidenav, { static: true }) matSidenav: MatSidenav;

  constructor(
    element: ElementRef,
    router: Router,
    renderer: Renderer2,
    translateService: TranslateService,
    appTranslateService: AppTranslateService,
    sidenavService: SidenavService,
    userService: UserService,
    angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
    channelTalkService: ChannelTalkService
  ) {
    router.events
      .pipe(
        takeUntil(this.unsubscriber),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(() => {
        this.matSidenav.close();
      });

    if (location.host === 'dashboard.buk.io') {
      angulartics2GoogleGlobalSiteTag.startTracking();
    }

    userService.user$
      .pipe(
        filter((user) => !!user),
        first(),
        filter((user) => user.country === 'kr')
      )
      .subscribe((user) => {
        channelTalkService.boot({
          pluginKey: 'bb43e130-d43c-4a42-ba3e-13abca7a885f',
          language: user.language === 'ko' ? 'ko' : 'en',
          memberId: sha256(user.id.toString()),
          zIndex: 999,
          profile: {
            name: `${user.name}(${user.email})`,
            email: user.email,
          },
        });
      });

    sidenavService.action$.subscribe((action) => {
      switch (action) {
        case 'open':
          this.matSidenav.open();
          break;
        case 'close':
          this.matSidenav.close();
          break;
        case 'toggle':
          this.matSidenav.toggle();
          break;
      }
    });

    // trigger load material icon font
    const icon = document.createElement('div');
    icon.style.visibility = 'hidden';
    icon.style.pointerEvents = 'none';
    icon.style.position = 'fixed';
    icon.classList.add('material-icons');
    icon.innerHTML = 'check_circle';

    renderer.appendChild(element.nativeElement, icon);
    setTimeout(() => {
      renderer.removeChild(element.nativeElement, icon);
    }, 1000);
  }

  logout(): void {
    location.href = `${
      environment.bukOrigin
    }/logout?redirect_to=${encodeURIComponent(location.href)}`;
  }
}
