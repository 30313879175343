import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import {
  TranslateModule,
  TranslateLoader,
  USE_EXTEND,
} from '@ngx-translate/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BukappMenuModule, BukappMenuConfigService } from '@bukio/bukapp-menu';
import { Angulartics2Module } from 'angulartics2';
import * as Sentry from '@sentry/angular';

import { SharedModule } from './shared/shared.module';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { MaterialCommonModule } from './material-common.module';
import { AuthGuard } from './auth.guard';
import { CustomTranslateLoaderService } from './custom-translate-loader.service';

@NgModule({
  declarations: [AppComponent, ErrorPageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    BukappMenuModule,
    Angulartics2Module.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoaderService,
      },
      defaultLanguage: 'en',
    }),
    RouterModule.forRoot(
      [
      {
        path: '',
        redirectTo: 'admin',
        pathMatch: 'full',
      },
        {
          path: 'dashboard',
          redirectTo: 'cp',
        },
        {
          path: 'channels',
          loadChildren: () =>
            import('./channels/channels.module').then((m) => m.ChannelsModule),
          canLoad: [AuthGuard],
        },
        {
          path: 'cp',
          loadChildren: () => import('./cp/cp.module').then((m) => m.CPModule),
          canLoad: [AuthGuard],
        },
        {
          path: 'admin',
          loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminModule),
          canLoad: [AuthGuard],
        },
        {
          path: '**',
          component: ErrorPageComponent,
        },
      ],
      { relativeLinkResolution: 'legacy' }
    ),
    MaterialCommonModule,
  ],
  providers: [
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    { provide: USE_EXTEND, useValue: true },
    {
      provide: BukappMenuConfigService,
      useFactory: () => ({
        apiEndpoint: environment.bukOrigin,
        bukOrigin: environment.bukOrigin,
        journalOrigin: environment.journalUrl,
        dashboardOrigin: location.origin,
      }),
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
