import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'currencyNumber',
})
export class CurrencyNumberPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number, currency: string): string {
    const lang = this.translate.currentLang === 'ko' ? 'ko' : 'en';

    let result;

    try {
      result = Number(value).toLocaleString(lang, {
        currency: currency === 'STEEM' || currency === 'SBD' ? 'USD' : currency,
      });
    } catch (error) {
      result = value;
    }

    return result;
  }
}
