<app-common-header>
  <div class="breadcrumbs">
    <span>{{ 'common.titles.dashboard' | translate }}</span>
  </div>
</app-common-header>
<main class="main">
  <mat-icon class="warning-icon">warning</mat-icon>
  <h2 class="mat-h1">
    {{
      (messages[code] ? messages[code] : 'error.messages.unknown')
        | translate: { code: code }
    }}
  </h2>
  <a mat-raised-button href="{{ bukOrigin }}">
    {{ 'error.actions.goToBukHome' | translate }}
  </a>
</main>
