import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  public readonly bukOrigin = environment.bukOrigin;

  public code: string;
  public messages = {
    '404': 'error.messages.404',
    '403': 'error.messages.403',
  };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.code = this.activatedRoute.snapshot.queryParamMap.get('code') || '404';
  }
}
