import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BukappMenuService } from '@bukio/bukapp-menu';

import { UserService } from '../../user.service';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private _action$ = new Subject<'open' | 'close' | 'toggle'>();
  public action$ = this._action$.asObservable();

  constructor(
    private bukAppMenuService: BukappMenuService,
    private userService: UserService
  ) {
    this.userService.user$.subscribe((user) => {
      this.bukAppMenuService.updateUser(user);
    });
  }

  open(): void {
    this._action$.next('open');
  }

  close(): void {
    this._action$.next('close');
  }

  toggle(): void {
    this._action$.next('toggle');
  }
}
