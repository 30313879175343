import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateLoader } from '@ngx-translate/core';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateLoaderService implements TranslateLoader {
  constructor(private _http: HttpClient) {}

  getTranslation(lang: string) {
    return this._http.get(
      `/assets/i18n/${lang}.json?${environment.i18nCacheBusting[lang]}`
    );
  }
}
