import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { SidenavService } from '../../../shared/services/sidenav.service';
import { UserService } from '../../../user.service';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit {
  @Input() hideMenuButton: boolean;

  public bukOrigin = environment.bukOrigin;
  public isLoggedIn$: Observable<boolean>;

  constructor(
    private sidenavService: SidenavService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.userService.user$.pipe(map((user) => !!user));
  }

  toggleSidenav(): void {
    this.sidenavService.toggle();
  }
}
