<mat-sidenav-container>
  <mat-sidenav #matSidenav mode="over" [autoFocus]="false">
    <div class="bukam-menu-wrapper">
      <button class="close-button" mat-icon-button (click)="matSidenav.close()">
        <mat-icon>close</mat-icon>
      </button>
      <bukam-header></bukam-header>
      <bukam-divider></bukam-divider>
      <div class="bukam-menu-user-panel">
        <bukam-menu></bukam-menu>
      </div>
    </div>
  </mat-sidenav>
  <router-outlet></router-outlet>
</mat-sidenav-container>
