import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, BehaviorSubject, Observable, zip } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  mergeMap,
  delay,
  shareReplay,
  tap,
} from 'rxjs/operators';

import { User } from './user.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user$: BehaviorSubject<User> = new BehaviorSubject(undefined);
  public user$: Observable<User> = this._user$
    .asObservable()
    .pipe(filter((user) => user !== undefined));

  constructor(private http: HttpClient) {
    this.fetchProfile().subscribe();
  }

  fetchProfile(): Observable<User> {
    return this.getProfile().pipe(
      tap(
        (user) => {
          this._user$.next(user);
        },
        (error) => {
          this._user$.next(null);
        }
      )
    );
  }

  getCurrentUser(): User {
    return this._user$.getValue();
  }

  private getProfile(): Observable<User> {
    return this.http
      .get<any>(`${environment.bukOrigin}/user/profile`, {
        withCredentials: true,
      })
      .pipe(
        map((response) => {
          return {
            id: response.id,
            name: response.displayName,
            email: response.email,
            language: response.language,
            channel: response.channel,
            country: response.country,
            /////
            displayName: response.displayName,
            publishers: response.publishers,
            books: response.books,
            ecobag: response.ecobag,
            services: response.services,
            isAdmin: response.isAdmin,
          };
        })
      );
  }
}
